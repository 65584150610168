import logo from './logo.svg';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import "@arco-design/web-react/dist/css/arco.css";
import { lazy, Suspense } from 'react'
import Page404 from './pages/Page404'
import { Skeleton } from 'antd-mobile/es/components/skeleton/skeleton';
const ItemPage = lazy(() => import('./pages/ItemPage'))
const CommentPage = lazy(() => import('./pages/CommentPage'))
const IndexPage = lazy(() => import('./pages/IndexPage'))
const CommentDetailPage = lazy(() => import('./pages/CommentDetailPage'))
const SendPage = lazy(() => import('./pages/SendPage'))
const CommentSendPage = lazy(() => import('./pages/CommentSendPage'))
const ILikePage = lazy(() => import('./pages/ILikePage'))
const UserPubPage = lazy(() => import('./pages/UserPubPage'))
const PermitUserPubPage = lazy(() => import('./pages/PermitUserPubItems'))
const UserProfileUpdatePage = lazy(() => import('./pages/UserProfileUpdatePage'))
const ChatScreen = lazy(() => import('./pages/chat/ChatPage'))
const LoginScreen = lazy(() => import('./pages/LoginPage'))
// const ChatScreen = lazy(() => import('./comment/chat/ChatScreen'))
const VideoPlayScreen = lazy(() => import('./pages/VideoFullScreenPlayScreen'))
const CalendarPage = lazy(() => import('./pages/calendar/CourseCalendarPage'))
const CalendarDetailPage = lazy(() => import('./pages/calendar/CalendarDetailPage'))
const SchoolSelectPage = lazy(() => import('./pages/SchoolSelectPage'))
const SendTypePage = lazy(() => import('./pages/SendTypePage'))
const LoginStep2 = lazy(() => import('./pages/LoginStep2'));
function App() {
  return (
    <div>
      <Routes>
        <Route path='/item/:itemId' element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><ItemPage /></Suspense>}>
          <Route path={'/item/:itemId/comment'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><CommentPage /></Suspense>} />
          <Route path={'/item/:itemId/comment/:commentId'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><CommentDetailPage /></Suspense>} />
        </Route>
        <Route path={'/item/home/:schoolId'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><IndexPage /></Suspense>}></Route>
        <Route path={'/user/ilike'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><ILikePage /></Suspense>}></Route>
        <Route path={'/user/item/home'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><IndexPage /></Suspense>}></Route>
        <Route path={'/user/pub/items'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><UserPubPage /></Suspense>}></Route>
        <Route path={'/other/user/:userId/pub/items'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><PermitUserPubPage /></Suspense>}></Route>
        <Route path={'/send/:schoolId/:itemId?'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><SendPage /></Suspense>} />
        <Route path={'/comment/send/:itemId/:commentId?'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><CommentSendPage /></Suspense>} />
        <Route path={'/user/profile/update'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><UserProfileUpdatePage /></Suspense>} />
        <Route path={'/item/video/play/:videoName'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><VideoPlayScreen /></Suspense>} />
        <Route path={'/user/calendar/:date?'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><CalendarPage /></Suspense>} />
        <Route path={'/user/calendar/detail/:id'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><CalendarDetailPage /></Suspense>} />
        <Route path={'/school/select'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><SchoolSelectPage /></Suspense>} />
        <Route path={'/send/type/:sendType/:itemId'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><SendTypePage /></Suspense>} />
        <Route path={'/chat/people/:otherId'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><ChatScreen /></Suspense>} />
        {/* <Route path={'/user/chatroom'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><ChatScreen /></Suspense>} /> */}
        <Route path={'/login'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><LoginScreen /></Suspense>} />
        <Route path={'/login2/:phone'} element={<Suspense fallback={<Skeleton avatar paragraph={{ rows: 4 }} active round />}><LoginStep2 /></Suspense>} />
        <Route path={'/'} element={<Page404 />} />
      </Routes>
    </div>
  );
}
export default App;
